import React, { Fragment } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import SEO from '../components/SEO';
import { synStrings } from '../consts/strings';
import mq from '../style/mq';
import { Container, Inner } from '../components/Elements';
import { colors } from '../consts/style';
import { socialUrls } from '../consts/urls';

const Wrapper = styled.div`
  position: absolute;
  overflow: hidden;
  top: 7.2rem;
  left: 0;
  height: calc(100% - 7.2rem);
  width: 100%;

  ${Container} {
    position: relative;
    z-index: 5;
    height: 100%;
    width: 100%;
  }
  ${Inner} {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const BgImg = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  z-index: 0;
`;

const synQuery = graphql`
  {
    bg: file(relativePath: { eq: "synopsis.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

const Content = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 40rem;
  background: ${colors.red};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  padding: 2.4rem;
  color: white;
  h1 {
    font-size: 4rem;
    text-transform: uppercase;
    font-family: 'Black Ops One';
    font-weight: normal;
  }
  h2 {
    font-size: 3rem;
    text-transform: uppercase;
    font-family: 'Black Ops One';
    font-weight: normal;
  }

  .socials {
    margin-top: 2rem;
    a,
    i {
      color: white;
      font-size: 2.4rem;
      margin-right: 0.6rem;
    }
  }

  ${mq.tabletSmall`
      width: 100%;
    overflow: scroll;
    background: #ef2a2a73;
  `}
`;

export default function Synopsis() {
  const data = useStaticQuery(synQuery);
  return (
    <Fragment>
      <SEO title={synStrings.title} description={synStrings.description} />
      <Wrapper>
        <BgImg
          backgroundColor={colors.blue}
          fluid={data.bg.childImageSharp.fluid}
        />
        <Content>
          <h1>Synopsis</h1>
          <p>
            When his new girlfriend gets deported on a technicality, Ross hires
            a fake husband to get her back into the country because he’s not
            ready to commit to marriage.
          </p>
          <h2>CAST & CREW</h2>
          <p>
            <strong>Producers:</strong> Ori Globus and Maurice Fadida
          </p>
          <p>
            <strong>Writers:</strong> Tyler Spindel and Dean Ward
          </p>
          <p>
            <strong>Director:</strong> Tyler Spindel
          </p>
          <p>
            <strong>Cast:</strong> Whitmer Thomas, Megan Park, Mickey Gooch Jr.,
            Nick Swardson, Steven Bauer, Missi Pyle, Amanda Cerny, Kurt Fuller,
            Brenda Strong, Jackie Sandler, Alexis Ren, Rob Gronkowski, Redfoo,
            Jay Alvarrez, Inanna Sarkis, Gregg Sulkin, Eric Roberts, Andy Dick,
            Robert Davi, Fortune Feimster and Jonathan Kite
          </p>
          <div className="socials">
            <a target="blank" href={socialUrls.facebook}>
              <i className="fab fa-facebook"></i>
            </a>
            <a target="blank" href={socialUrls.twitter}>
              <i className="fab fa-twitter"></i>
            </a>
            <a target="blank" href={socialUrls.instagram}>
              <i className="fab fa-instagram"></i>
            </a>
            <a target="blank" href={socialUrls.imdb}>
              <i className="fab fa-imdb"></i>
            </a>
          </div>
        </Content>
      </Wrapper>
    </Fragment>
  );
}
